import './App.css';
import headerBackgroundLeft from "./header-background-left.png";
import headerBackgroundRight from "./header-background-right.png";
import profilePicture from "./profile-picture.jpg";
import nicoleWorking from "./nicole-working.jpg";

function App() {
  return (
    <div className="app">
      <header className="app-header">
        <div className="header-background">
          <img src={headerBackgroundLeft} alt="header-background-left" className="left" />
          <img src={headerBackgroundRight} alt="header-background-right" className="right" />
        </div>
        <div className="header-box">
          <h1>NVC</h1>
        </div>
      </header>
      <section className="app-body">
        <a href="mailto:design@nicolecorea.com" className="contact">Contact</a>
        <div className="container container-padding">
          <div className="app-column column-padding column-left">
            <h2>NICOLE VICTORIA COREA</h2>
            <p>is a Designer/Director serving clients, agencies, and institutions for over 16 years—working 10 years in New York City, followed by overseas experience that enhanced her world view. Her design work is both expansive and diverse including projects for Microsoft, Google, NBC Universal, Hearst Corporation, and Verizon Wireless. Accomplished at symbol design, she developed brand identity programs for both corporations and nonprofits. Some notable projects include marketing material for Lincoln Center; and concept design, illustration, and projection art for Julie Taymor’s theatrical production of “A Midsummer Night's Dream”.</p>
            <p>Early in her career, Nicole was attracted to digital design and motion graphics, developing an expertise that enabled her to strategize and implement web design requiring complex communications. Nicole is skilled at leading and managing multi-disciplined teams to produce work that elevates brands and generates rewarding customer experiences.</p>
            <p>Her work has won awards in Print Magazine, Graphic Design USA, and she received the DeRose-Hinkhouse Award for Best in Class Public Relations Material.</p>
          </div>
          <div className="app-column column-padding column-right">
            <h2 className="faded">CLIENTS, BRANDS &amp; EMPLOYERS</h2>
            <div className="flex">
              <div className="list-column">
                <ol className="workplaces small-text">
                  <li>AARP Health Care</li>
                  <li>Amesbury</li>
                  <li>AVAYA</li>
                  <li>Bahá’í International Community</li>
                  <li>Bahá’í World Centre</li>
                  <li>Bank of America</li>
                  <li>Cause</li>
                  <li>Citation Products</li>
                  <li>Columbia University</li>
                  <li>Digitas</li>
                  <li>Draft World Wide/Draft FCB</li>
                  <li>Embraer Executive Jets</li>
                  <li>Foodlink</li>
                  <li>Gilmore Group</li>
                  <li>Google</li>
                  <li>Hearst Corporation</li>
                  <li>Hewlett Packard</li>
                  <li>Idea Connections</li>
                  <li>IMC2</li>
                  <li>Joseph Avenue Cultural Alliance</li>
                </ol>
              </div>
              <div className="list-column">
                <ol className="workplaces small-text">
                  <li>John Freida</li>
                  <li>Juniper Content Corporation</li>
                  <li>Launchpad Advertising</li>
                  <li>Lincoln Center for the Performing Arts</li>
                  <li>Microsoft</li>
                  <li>NBC Universal</li>
                  <li>Noiland LLC</li>
                  <li>Real Estate Arts</li>
                  <li>Rochester Institute of Technology</li>
                  <li>San Francisco Chronicle</li>
                  <li>Santander Bank</li>
                  <li>Snuggle</li>
                  <li>Suka Creative</li>
                  <li>Switzer + Knight</li>
                  <li>TIAA-CREF Asset Management</li>
                  <li>University of Rochester</li>
                  <li>Wells Fargo</li>
                  <li>White &amp; Case LLP</li>
                  <li>World Bank</li>
                  <li>Verizon Wireless</li>
                </ol>
              </div>
            </div>
            <h2 className="faded">Education</h2>
            <div className="flex-container">
              <img src={nicoleWorking} alt="nicole-working" className="nicole-working" />
              <p className="small-text">Nicole earned a BFA from Rhode Island School of Design in Graphic Design and a Masters in Science for Teaching Visual Art from Rochester Institute of Technology. Nicole also teaches design to students interested in pursuing a career in communications.</p>
            </div>
            <h2 className="faded">Portfolio</h2>
            <p className="small-text portfolio">Contact Nicole to see work samples relevant to your interests at <a href="mailto:design@nicolecorea.com" className="inline-link">design@nicolecorea.com</a>.</p>
          </div>
        </div>
      </section>
      <section className="app-footer">
        <hr />
        <p id="copyright">© 2021 nicolecorea.com</p>
        <img src={profilePicture} alt="portrait" className="portrait"/>
      </section>
    </div>
  );
}

export default App;
